.container {
  max-width: 1280px;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden;
}

.btn-main,
.btn-light,
.btn-dark {
  display: inline-block;
  padding: 1.2rem 1.2rem;
  border: none;
  cursor: pointer;
}

.btn-main,
.btn-light,
.btn-dark {
  display: inline-block;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
  border: none;
  cursor: pointer;
}

.btn-main {
  color: #333;
  background-color: #ffbc00;
}

.btn-light {
  color: #333;
  background-color: #f4f4f4;
}

.btn-dark {
  color: #f4f4f4;
  background-color: #333;
}

button[class^='btn-']:hover,
a[class^='btn-']:hover,
input[class^='btn-']:hover {
  background-color: #ffbc00;
}

.bg-main {
  background: #ffbc00;
  color: #fff;
}

.bg-dark {
  background: #333;
  color: #fff;
}

.bg-light {
  background: #f4f4f4;
  color: #000;
}

.bg-medium {
  background: #ccc;
  color: #000;
}

.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

.py-4 {
  padding: 4rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.my-4 {
  margin: 4rem 0;
}

.section-title {
  font-size: 2rem;
  display: block;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
}

.bottom-line {
  height: 2px;
  width: 3rem;
  background: #ffbc00;
  display: block;
  margin: 0 auto 1rem auto;
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

.item {
  position: relative;
  background: #ffbc00;
  overflow: hidden;
}
.item::after {
  content: '';
  position: absolute;
  display: block;
  background: inherit;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
  transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
}
.item:hover:after {
  transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
}
.item:hover .item-image {
  transform: scale(1.2);
}
.item:hover .item-text {
  opacity: 1;
  transform: translateY(0);
}
.item-image:before {
  content: '';
  display: block;
  padding-top: 75%;
  overflow: hidden;
}
.item-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  line-height: 0;
}
.item-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  z-index: 1;
  color: #000;
  transform: translateY(-20%);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition-delay: 300ms;
}
.item-text-wrap {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.item-text-title {
  font-size: 2rem;
  padding: 0 1rem;
  margin: 5px 0 0 0;
}
.item-text-category {
  text-transform: uppercase;
  font-size: 1.2rem;
  opacity: 0.7;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Dosis', sans-serif;
  line-height: 1.6;
  background: #fff;
}

a {
  text-decoration: none;
  color: #333;
}

ul {
  list-style: none;
}

h2,
h3,
h4 {
  text-transform: uppercase;
}

img {
  width: 100%;
}

.logo {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
}

#main-nav {
  display: flex;
  justify-content: space-between;
}
#main-nav ul {
  display: flex;
}
#main-nav li {
  padding: 1rem 1.5rem;
}
#main-nav a:hover {
  border-color: #ccc;
}
#main-nav a {
  color: #fff;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  border-bottom: 3px transparent solid;
  transition: border-color 0.5s ease-in-out;
}
#main-nav a.current {
  border-color: #ffbc00;
}

#header-home {
  background: url('./img/showcase.jpg') no-repeat center right/cover;
  height: 100vh;
  color: #fff;
}
#header-home .header-content {
  text-align: center;
  padding-top: 20%;
}
#header-home .header-content h1 {
  font-size: 4rem;
  line-height: 1.2;
}

#header-inner {
  background: url('./img/showcase.jpg') no-repeat 20% 30% / cover;
  height: 5.5rem;
  border-bottom: 3px solid #ffbc00;
}

#home-a .specials {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.6rem;
}
#home-a .specials i {
  color: #ffbc00;
}

#home-b .stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
#home-b .stats li {
  line-height: 2;
}
#home-b .stats li.stats-title {
  font-size: 1.5rem;
}
#home-b .stats li.stats-number {
  font-size: 2rem;
  font-weight: bold;
}
#home-b .stats div {
  padding: 3rem 0;
}
#home-b .stats div:nth-child(odd) {
  background: #f4f4f4;
}
#home-b .stats div:nth-child(even) {
  background: #ccc;
}
.contentabout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#home-c .process {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  justify-content: center;
}
#home-c .process-step {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  font-size: 28px;
  background: #ffbc00;
  color: #f4f4f4;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  line-height: 15px;
  padding: 1rem;
  transition: all 1s;
}
#home-c .process-icon {
  position: relative;
  background: #333;
  color: #f4f4f4;
  padding: 2rem;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  line-height: 70px;
  transition: all 1s;
}
#home-c .process-icon:hover {
  transform: scale(1.1);
  background: #ffbc00;
}
#home-c .process-icon:hover .process-step {
  background: #333;
  color: #fff;
}

#main-footer {
  padding: 2rem;
  background: #333;
  color: #fff;
}
#main-footer .footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#main-footer .footer-content .social {
  display: flex;
  align-items: center;
  justify-content: space-around;
  display: flex;
}
#main-footer .footer-content .social i {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px #fff solid;
  border-radius: 50%;
  cursor: pointer;
}
#main-footer .footer-content .social i:hover {
  color: #ffbc00;
  border-color: #ffbc00;
}

#about-a .about-info {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 3rem;
}
#about-a .about-info .bio {
  border-left: 3px #ffbc00 solid;
}
#about-a .about-info h4 {
  margin-top: 1rem;
}

#about-a .awards {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  gap: 3rem;
}
#about-a .awards i {
  color: #ffbc00;
}

.scroll-down {
  height: 50px;
  width: 30px;
  border: 2px solid white;
  position: absolute;
  left: 50%;
  bottom: 20px;
  border-radius: 50px;
  cursor: pointer;
}
.scroll-down::before,
.scroll-down::after {
  content: '';
  position: absolute;
  top: 20%;
  left: 50%;
  height: 10px;
  width: 10px;
  transform: translate(-50%, -100%) rotate(45deg);
  border: 2px solid white;
  border-top: transparent;
  border-left: transparent;
  animation: scroll-down 1s ease-in-out infinite;
}
.scroll-down::before {
  top: 30%;
  animation-delay: 0.3s;
  /* animation: scroll-down 1s ease-in-out infinite; */
}

@keyframes scroll-down {
  0% {
    /* top:20%; */
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    top: 90%;
    opacity: 0;
  }
}

#about-b .progress {
  overflow: hidden;
  height: 1.2rem;
  background: #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
}
#about-b .progress div {
  height: 100%;
  color: #fff;
  text-align: center;
  background: #ffbc00;
}

#about-c .about-logos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}
#about-c .about-logos img {
  width: 230px;
  height: 55px;
}

#about-d .testimonials {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
#about-d .testimonials p {
  border: 1px #ccc solid;
  width: 90%;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
#about-d .testimonials ul {
  display: flex;
  align-items: center;
}
#about-d .testimonials ul li img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

#contact-a .text-fields {
  display: grid;
  grid-template-areas:
    'name email'
    'subject phone'
    'message message';
  grid-gap: 1.2rem;
  margin-bottom: 1.2rem;
}
#contact-a .text-fields .name-input {
  grid-area: name;
}
#contact-a .text-fields .subject-input {
  grid-area: subject;
}
#contact-a .text-fields .email-input {
  grid-area: email;
}
#contact-a .text-fields .phone-input {
  grid-area: phone;
}
#contact-a .text-fields .message-input {
  grid-area: message;
  height: 100px;
}
#contact-a .text-fields .text-input {
  padding: 0.5rem 1rem;
}

#contact-a button[type='submit'] {
  width: 50%;
  transition: all 1s ease-in-out;
}

#contact-a button[type='submit']:hover {
  content: '';
  opacity: 0.9;
}

#contact-b .contact-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}

#contact-c h1 {
  text-align: center;
  font-size: 4rem;
}

.github-button {
  padding: 0 10px;
  height: 28px;
  line-height: 26px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
  color: #333;
  margin-bottom: 5px;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  display: inline-block;
  font-weight: 700;
  font-size: 13px;
  vertical-align: top;
  background: #fff;
  background-color: #eee;
  background: -webkit-linear-gradient(#fcfcfc, #eee);
  background: -moz-linear-gradient(#fcfcfc, #eee);
  background: -ms-linear-gradient(#fcfcfc, #eee);
  background: -o-linear-gradient(#fcfcfc, #eee);
  background: linear-gradient(#fcfcfc, #eee);
}

@media (max-width: 768px) {
  #about-d .testimonials {
    grid-template-columns: 1fr 1fr;
  }
  #about-c .about-logos {
    grid-template-columns: 1fr;
  }
  #about-c .about-logos img {
    width: 100%;
    height: 65%;
  }
  #header-home {
    height: 30rem;
  }
  .items {
    grid-template-columns: 1fr 1fr;
  }
  #contact-a .text-fields {
    grid-template-areas:
      'name'
      'email'
      'subject'
      'phone'
      'message';
  }
}

@media (max-width: 600px) {
  #about-d .testimonials {
    grid-template-columns: 1fr;
  }
  #about-c .about-logos {
    grid-template-columns: 1fr;
  }
  #about-c .about-logos img {
    width: 100%;
    height: 65%;
  }
  #about-a .about-info,
  #about-a .awards {
    grid-template-columns: 1fr;
  }
  #main-footer .footer-content {
    flex-direction: column;
  }
  #main-nav {
    flex-direction: column;
    align-items: center;
  }
  #main-nav li {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
  #main-nav li a {
    font-size: 1rem;
  }
  #header-home {
    height: 8rem;
  }
  #header-home .header-content {
    display: none;
    font-size: 20px;
  }
  #header-home h1 {
    display: none;
  }
  #header-inner {
    height: 8rem;
  }
  #home-a .specials {
    grid-template-columns: 1fr;
  }
  #home-b .stats {
    grid-template-columns: 1fr;
  }
  #home-c .process {
    grid-template-columns: 1fr;
  }
  .items {
    grid-template-columns: 1fr;
  }
  #contact-a .text-fields {
    grid-template-areas:
      'name'
      'email'
      'subject'
      'phone'
      'message';
  }
  #contact-b .contact-info {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  #contact-c {
    font-size: 1rem;
  }
}
